const labelColors = {
  "A+++++": "#029246",
  "A++++": "#029246",
  "A+++": "#029246",
  "A++": "#029246",
  "A+": "#029246",
  A: "#029246",
  B: "#53b039",
  C: "#a6c92b",
  D: "#fce510",
  E: "#f6a210",
  F: "#eb5d19",
  G: "#e51c20",
  "": "#CCCCCC",
};

const floorAreaColors = {
  0: "#48b5c4",
  500: "#22a7f0",
  1000: "#1984c5",
  5000: "#115f9a",
};

const appliedStatusColors = {
  Applicable: "#FFFF00", // yellow
  ApplicableButNotPresent: "#7f2b0a", // reddishbrown
  ApplicableButNotProfitable: "#9B7A01", // yellowbrown
  ApplicableButNotTechnicallyFeasible: "#f0dc82", // buff
  NotApplicableObviousMomentNotReached: "#ac8d13", // Desaturate
  ApplicableButObviousMomentNotReached: "#d1b549", // brownish
  ApplicableButPartlyExecuted: "#9acd32", // yellowgreen
  InvalidConditions: "#FF9B00", // orange
  Executed: "#008000", // green
  NotApplicable: "#800080", // purple
  Unknown: "#F8C8DC", // yellowishpink
  None: "#D3D3D3", // lightgrey
};

const innaxColor = {
  blue: "#00A2DF",
};

const appliedType = {
  Erkend: "#549C14",
  Aanvullend: "#00A2DF",
};

const returnOnInvestment = {
  "< 1 jaar": "#549C14",
  "1 t/m 5 jaar": "#AAD962",
  "6 t/m 10 jaar": "#EEDB28",
  "> 10 jaar": "#CC4747",
};

function getParisProofColorClass(score, norm) {
  // console.log("colors.js, getParisProofColorClass, score, norm:", score, norm);
  // In geval van WEii "Industriefunctie - Bedrijfshal" verwarmd is de norm negatief (-25) en zou de Eiffeltoren veel te snel rood worden.
  // Het is niet ideaal, maar in overleg is voor nu de norm in de oranje range absoluut gemaakt. Deze range wijkt dan wel af van 
  // gewone positieve normen maar de Eiffeltoren moet t.z.t. toch aangepast worden.

  let normAbs = Math.abs(norm);
  if (!Number.isNaN(score) && !Number.isNaN(norm)) {
    if (score <= norm) {
      return "color-green";
    } else if (score > norm && score <= normAbs * 2.0) {
      return "color-orange";
    } else {
      return "color-red";
    }
  }
  // de color classes zijn gedefineerd in _tables.scss
  return "color-grey";
}

function getHouseColorClass(demand, standard) {
  if (!Number.isNaN(demand) && demand > 0 && !Number.isNaN(standard) && standard > 0) {
    if (demand <= standard) {
      return "color-green";
    } else if (demand > standard && demand <= standard * 2.0) {
      return "color-orange";
    } else {
      return "color-red";
    }
  }
  // de color classes zijn gedefineerd in _tables.scss
  return "color-grey";
}

function randomColor() {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

function colorFromString(stringInput) {
  // Mischien ergens nog een keer handig
  // https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

export { labelColors, floorAreaColors, appliedStatusColors, innaxColor, appliedType, returnOnInvestment, getParisProofColorClass, getHouseColorClass, randomColor, colorFromString };
